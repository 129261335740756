<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4 pb-6">
      <div class="row mb-4 text-center">
        <div class="col-md-12">
          <h3>Update Product</h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <label class="form-control-label">Label</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Add Label (Name to be displayed)"
            v-model="data.label"
            rows="4"
          />
          <label
            v-for="error in errors.label"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
        <!-- <div class="col-md-6">
          <label class="form-control-label">Name</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Add Name as a document id"
            v-model="data.name"
            rows="4"
          />
          <label
            v-for="error in errors.name"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div> -->
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label class="form-control-label">Icon</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="update products icon url"
            v-model="data.icon"
            rows="4"
          />
          <label
            v-for="error in errors.icon"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
        <div class="col-md-6">
          <label class="form-control-label">Order Number</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Add how products will be ordered"
            v-model="data.orderNo"
          />
          <label
            v-for="error in errors.orderNo"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <label class="form-control-label">Is Active?</label>
          <span class="ml-1 mr-1 text-danger">*</span>
          <input
            class="form-control-alternative"
            v-model="data.isActive"
            type="checkbox"
          />
          <label
            v-for="error in errors.isActive"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <label class="form-control-label">Path</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Add the path"
            v-model="data.path"
            rows="4"
          />
          <label
            v-for="error in errors.path"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-8 mt-2">
          <button class="btn btn-secondary px-6" @click="this.$router.go(-1)">
            Cancel
          </button>
          <!-- <button-loader :title="btnTitle" :classes="classes" :show_loader="show_loader" @click="saveServiceCatalog()"/> -->
          <button
            :disabled="show_loader"
            class="btn btn-md btn-default my-2 px-4"
            :style="!show_loader ? '' : styleObject"
            @click="updateProduct()"
          >
            Update Product
            <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
export default {
  data() {
    return {
      selected: 0,
      data: {},
      errors: {},

      // button styles
      styleObject: {
        cursor: "not-allowed",
      },
      btnTitle: "Add Collection",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
  methods: {
    getCollection: function() {
      const ref = doc(db, "services", this.$route.params.uuid);
      getDoc(ref)
        .then((docSnap) => {
          if (docSnap.exists()) {
            this.data = docSnap.data();
          }
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch(() => {
          this.data_loaded = false;
        });
      console.log("get data");
    },

    updateProduct: function() {
      this.show_loader = true;
      // const collectionRef = collection(db, "services").doc(this.data.name)
      updateDoc(doc(db, "services", this.$route.params.uuid), {
        ...this.data,
      })
        .then(() => {
          this.show_loader = false;
          console.log("updated successfully");
          alert.success_center("You have successfully updated product");
          this.$router.push({ name: "Products" });
        })
        .catch((e) => {
          alert.error("an error occured please try again");
          console.log(e);
        });
    },
  },
  created(){
      this.getCollection()
  }
};
</script>

<style lang="scss" scoped></style>
